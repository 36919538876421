<template>
  <div id="app">
    <Navbar></Navbar>
    <router-view/>
    <Footer></Footer>
    <div class="privacy">
      <div class="privacy-inner">
        <span>We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</span>
        <button class="pc-ok">OK</button>
      </div>
    </div>
  </div>
</template>
<script>
  import Navbar from './components/Navbar.vue'
  import Footer from './components/Footer.vue'
  export default{
    name:'App',
    components:{
      Navbar,
      Footer
    }
  }
</script>
<style lang="scss">
  .privacy{
    position: fixed;
    bottom:0;
    left: 0;
    right: 0;
    display: none;
  }
  .privacy-inner{
    width: 100%;
    padding: 15px 30px;
    text-align: center;
    background-color: #181A20;
    box-sizing: border-box;
    color: #fff;
  }
  .pc-ok{
    background-color: #f7bd05;
    color: #212833;
    font-size: 14px;
    border:none;
    border-radius: 2px;
    padding: 3px 6px;
    cursor: pointer;
    margin-left: 10px;
  }
</style>
