<template>
	<div>
		<footer class="section bg-dark">
	  		<div class="container">
	  			<div class="flex">
	  				<div class="ft-items">
	  					<h3>Company</h3>
	  					<div class="ft-links">
	  						<div>
	  							<router-link to="/about">About</router-link>
	  						</div>
	  						<div>
	  							<router-link to="/terms">Terms & Privacy Policy</router-link>
	  						</div>
	  					</div>
	  				</div>
	  				<div class="ft-items">
	  					<h3>Support</h3>
	  					<div class="ft-links">
	  						<div>
	  							<router-link to="/trade">OTC Trade</router-link>
	  						</div>
	  						<!-- <div>
	  							<router-link to="/fees">Fees</router-link>
	  						</div> -->
	  					</div>
	  				</div>
	  				<div class="ft-items">
	  					<h3>Contact</h3>
	  					<div class="ft-links">
	  						<div>
	  							<a href="#">Email: info@bitfree.digital</a>
	  						</div>
	  						<div>
	  							<a href="#">Phone: (+852) 9837263</a>
	  						</div>
	  					</div>
	  				</div>
	  			</div>
	  		</div>
	  	</footer>
	  	<div class="copyright">
			<div class="container">
				&copy; 2021 BitFree Capital - All rights reserved.
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		name:'Footer'
	}
</script>
<style lang="scss" scoped>
	.ft-items{
		width: 33.33%;
		h3{
			font-size: 15px;
			font-weight: 600;
			margin-bottom: 10px;
			color: #fff;
		}
	}
	.ft-links a{
		display: inline-block;
		padding: 10px 0;
		color: #fafafa;
	}
	.copyright{
		padding: 20px 0;
		background-color: #0A0B11;
		text-align: center;
	}
</style>