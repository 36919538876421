<template>
	<div class="nav" :class="{'nav-dark': dark}">
		<div class="container">
			<div class="flex flex-center">
				<div class="logo">
					<router-link to="/">BitFree Capital</router-link>
				</div>
				<div class="flex-fill">
					<div class="nav-links flex-center">
						<router-link to="/trade">Trade</router-link>
						<router-link to="/about">About</router-link>
						<router-link to="/terms">FAQ</router-link>
						<router-link to="/contact">Contact</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		name:'Navbar',
		data(){
			return {
				dark: false
			}
		},
		watch:{
			$route(to, from){
				let name = to.name
				if (name=='Trade'||name=='Terms'||name=='Contact') {
					this.dark = true
					window.removeEventListener('scroll', this.onScroll)
				}else{
					this.dark = false
					window.addEventListener('scroll', this.onScroll)
				}
			}
		},
		methods: {
	      onScroll() {
	        let scroll = document.documentElement.scrollTop || document.body.scrollTop
	        if (scroll >= 350) {
	        	this.dark = true
	        }else{
	        	this.dark = false
	        }
	      }
	    }
	}
</script>
<style lang="scss">
	.nav{
		height: 64px;
		background-color: rgba(0,0,0,0.2);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
	}
	.nav-dark{
		background-color: #181A20;
	}
	.logo a{
		font-size: 16px;
		font-weight: 600;
		color: #efb90a;
	}
	.nav-links{
		height: 64px;
		display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    margin-left: auto;
	    -webkit-box-pack: end;
	    -ms-flex-pack: end;
	    justify-content: flex-end;
	    a{
	    	margin-left: 30px;
	    	font-size: 15px;
	    	font-weight: 600;
	    	color: #EAECEF;
	    }
	    a:hover{
	    	color: #efb90a !important;
	    }
	}
	.router-link-active{
		color: #efb90a !important;
	}
</style>