<template>
  <div class="home">
  	<section class="banner" style="background-image: url(/imgs/trade.jpg);background-size: cover;">
  		<div class="container">
	  		<div class="slogo">
	  			<p>HongKong'S Over The Counter (OTC) Trading Centre for Digital Assets</p>
	  			<div class="start">
	  				<button class="start-btn" @click="trade">START TRADE</button>
	  			</div>
	  		</div>
  		</div>
  	</section>
  	<section class="market">
  		<div class="container">
  			<div class="market-items flex flex-center">
	  			<div class="item" v-for="coin in market">
	  				<div class="symbol flex flex-center">
	  					<img :src="coin.icon">
	  					<span>{{coin.symbol}}</span>
	  				</div>
	  				<div class="price">${{coin.price}}</div>
	  			</div>
  			</div>
  		</div>
  	</section>
  	<section class="section">
  		<div class="container">
	  		<h3 class="tc fw section-title">Hong Kong’s Premier OTC Trading Centre</h3>
	  		<p class="section-sub-title">BitFree Capital is proudly serving our community as the first stop for all things Bitcoin, Crypto, and Blockchain.</p>
	  		<div class="features flex flex-center">
	  			<div class="feature">
	  				<div class="icon">
	  					<img src="/imgs/icons8-exchange-58.png">
	  				</div>
	  				<div class="title">OTC Trading</div>
	  			</div>
	  			<div class="feature">
	  				<div class="icon">
	  					<img src="/imgs/icons8-protect-48.png">
	  				</div>
	  				<div class="title">Compliance & Security</div>
	  			</div>
	  			<div class="feature">
	  				<div class="icon">
	  					<img src="/imgs/icons8-customer-support-48.png">
	  				</div>
	  				<div class="title">Reliable Service</div>
	  			</div>
	  		</div>
  		</div>
  	</section>
  	<section class="bg-dark" style="padding: 80px 0;">
  		<div class="container support">
  			<h3 class="tc">Multi Digital Assets Support</h3>
  			<p class="section-sub-title mb20">BitFree Capital is here to help you buy, sell, or learn anything cryptocurrency and blockchain.</p>
			<p class="section-sub-title">There are staple cryptos like Bitcoin, Ethereum, USDT, USDC, Litecoin, and Bitcoin Cash that we can easily help you transact.</p>
  		</div>
  	</section>
  	<section class="section bg-gray">
  		<div class="container">
  			<h3 class="tc section-title mb40">Get Started in a Few Minutes</h3>
  			<div class="steps flex flex-center">
  				<div class="step">
  					<div class="step-icon">
  						<img src="/imgs/icons8-worker-id-card-48.png">
  					</div>
  					<div class="step-title">
  						Register your account
  					</div>
  				</div>
  				<div class="step-arr">
  					<div class="stepa">
  						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1iztezc"><path d="M14.65 12.24l-4.24 4.24L9 15.06l2.82-2.82L9 9.42 10.41 8l4.24 4.24z" ></path></svg>
  					</div>
  				</div>
  				<div class="step">
  					<div class="step-icon">
  						<img src="/imgs/icons8-name-48.png">
  					</div>
  					<div class="step-title">
  						Meet your account manager
  					</div>
  				</div>
  				<div class="step-arr">
  					<div class="stepa">
  						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1iztezc"><path d="M14.65 12.24l-4.24 4.24L9 15.06l2.82-2.82L9 9.42 10.41 8l4.24 4.24z" ></path></svg>
  					</div>
  				</div>
  				<div class="step">
  					<div class="step-icon">
  						<img src="/imgs/icons8-transaction-48.png">
  					</div>
  					<div class="step-title">
  						Buy & sell crypto
  					</div>
  				</div>
  			</div>
  		</div>
  	</section>
  	<section class="section">
  		<div class="container">
  			<h1 class="section-title">Start Your Crypto Trade Journey</h1>
  			<div class="tc">
  				<button class="start-btn" @click="trade">START TRADE</button>
  			</div>
  		</div>
  	</section>
  </div>
</template>

<script>
	import axios from 'axios'
	export default {
	  name: 'Home',
	  data(){
	  	return {
	  		market:[]
	  	}
	  },
	  created(){
	  	this.getMarket()
	  },
	  methods:{
	  	trade(){
	  		this.$router.push('/trade')
	  	},
	  	getMarket(){
	  		axios.get('http://bitfree.digital/api/open/market').then(res=>{
	  			if (res.data.code==0) {
	  				this.market = res.data.data
	  			}
	  		})
	  	}
	  }
	}
</script>
<style lang="scss" scoped>
	.banner{
		height: 480px;
		width: 100%;
	}
	.slogo{
		padding-top: 160px;
		font-size: 28px;
		font-weight: 600;
		color: #fff;
	}
	.start{
		margin-top: 50px;
	}
	.start-btn{
		padding: 10px 16px;
		background-color: #f7bd05;
		color: #212833;
		font-size: 15px;
		font-weight: 600;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		transition: all ease-in .3s;
	}
	.start-btn:hover{
		background-color: #d19f02;
	}
	.market{
		padding: 30px 0;
		background-color: #fafafa;
	}
	.item{
		width: 20%;
		.symbol{
			margin-bottom: 10px;
			font-size: 14px;
			font-weight: 600;
			img{
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
		}
		.price{
			font-size: 16px;
			font-weight: 600;
	
		}
	}
	.support{
		h3{
			font-size: 26px;
			font-weight: 600;
			color: #fff;
			margin-bottom: 50px;
		}
	}
	.features{
		margin-top: 50px;
	}
	.feature{
		width: 33.33%;
		text-align: center;
		.icon img{
			height: 48px;
		}
		.title{
			margin-top: 20px;
			font-size: 16px;
			font-weight: 600;
		}
	}
	.steps{
		.step{
			width: 20%;
			padding: 30px 10px;
			background-color: #f3f5f9;
			border-radius: 8px;
			text-align: center;
		}
		.step-arr{
			width: 20%;
			text-align: center;
			img{
				height: 32px;
			}
		}
		.step-title{
			font-size: 15px;
			margin-top: 30px;
			font-weight: 600;
		}
	}
	.stepa{
		box-sizing: border-box;
		margin: 0 auto;
	    width: 24px;
	    height: 24px;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    background-color: #FCD535;
	    border-radius: 50%;
	    -webkit-align-items: center;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -ms-flex-pack: center;
	    justify-content: center;
	    color: #707A8A;
	}
	.css-1iztezc {
	    box-sizing: border-box;
	    margin: 0;
	    min-width: 0;
	    color: currentColor;
	    width: 24px;
	    height: 24px;
	    font-size: 24px;
	    fill: #000;
	}
</style>